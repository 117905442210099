$(window).scroll(function(){
  var sticky = $('.sticky'),
      scroll = $(window).scrollTop();
  if (scroll >= 5) sticky.addClass('fixed');
  else sticky.removeClass('fixed');
});

$(window).scroll(function(){
  var stickycontent = $('.content'),
      scroll = $(window).scrollTop();
  if (scroll >= 5) stickycontent.addClass('content-fixed');
  else stickycontent.removeClass('content-fixed');
});
